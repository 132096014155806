import React, { useContext, useEffect } from "react";
import AppContext from "./AppContext";


const ChoiceModal = (props) => {
  const myContext = useContext(AppContext);


  let food = myContext.dataModal
  let temp


  // Count
  let count = 0

  // Declare quantities
  const quantities = []
  for (let i = 1; i < parseInt(myContext.quantityMax)+1; i++) {
    quantities.push({value: i})
  }
  
  // Update count & price
  const updateCount = (event) =>{
    count = event.target.value
    document.querySelector('.choice-modal-price').textContent = count * food.price + "€"
  }




  // Choice 
  let tempChoice = []

  // Remove choice
  const removeInitChoice = ()=>{
    temp = JSON.parse(JSON.stringify(food))
    Object.keys(temp.choice).map((key, index)=>{
      tempChoice[index] = (temp.choice.[key].option.[0])
      temp.choice.[key].option.map((opt, index)=>{
        delete temp.choice.[key].option.[index]
      })
      tempChoice.map((key, index2) => {
        temp.choice.[index2].option.[0] = (tempChoice[index2])
      })
    })
  }

  useEffect(() => {
    removeInitChoice()
  }, []);


  // Update choice
  const updateChoice = (event, index) =>{
    tempChoice[index] = event.target.value;
    tempChoice.map((key, index2) => {
      temp.choice.[index2].option.[0] = (tempChoice[index2])
    })
  }



  return (
    <div className="choice-modal" id="choice-modal">
      <div className="choice-modal-close-container" onClick={()=>{myContext.setShowModal(false)}}>
        <img src='/svg/close.svg' alt="close"/>
      </div>
      <div className="choice-modal-container">
        <div className="choice-modal-title">{food.name}</div>
        {Object.keys(food.choice).map((key, index)=>{
          const tempkey = "select_" + key
          return(
            <div key={key} className="choice-modal-option-container">
              <label className="label" htmlFor={key}>{food.choice.[key].title}</label>
              <select className="select" id={tempkey} onChange={(event)=>{updateChoice(event, index)}}>
                {food.choice.[key].option.map((option, index)=>{
                  return (
                    <option key={option}>{option}</option>
                  )
                })}
              </select>
            </div>
          )})
        }
        <div className="choice-modal-option-container">
          <label className="label" htmlFor="choice-modal-quantity">Quantité</label>
          <select id="choice-modal-quantity" onChange={(event)=>{updateCount(event)}} className="select" name="food-card-quantity">
            {quantities.map((quantity, index) =>(
              <option value={quantity.value} key={index}>{quantity.value}</option>
            ))}
          </select>
        </div>
        <div className="choice-modal-price-container">
          <div className="choice-modal-price-title">Total</div>
          <div className="choice-modal-price">{food.price}€</div>
        </div>

        <div onClick={
          ()=>{
            myContext.setShowModal(false);
            myContext.addCart(temp, count, true);
            }
          } className="choice-modal-button button-primary small">
            Ajouter
        </div>
        <div className="button-primary invisible choice-modal-close-text" onClick={()=>{myContext.setShowModal(false)}}>Annuler</div>
      </div>
    </div>
  )
}

export default ChoiceModal
