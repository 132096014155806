import React, { useState } from 'react';
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import { Route, Switch } from 'react-router-dom'
import AppContext from './components/AppContext';


import ScrollToTop from './components/ScrollToTop';

import Home from './pages/Home'
import ChoiceModal from './components/ChoiceModal';


function App() {

  // Modal
  const [showModal, setShowModal] = useState(false)
  const [dataModal, setDataModal] = useState({})

  // Cart
  const quantityMax = parseInt(20)

  const [cart, setCart] = useState({
    // 0: {
    //   id: "entreeTest",
    //   name: "nomTest",
    //   choice: "choixTest",
    //   price: "5",
    //   count: 1
    // },
  });

  const [totalPrice, setTotalPrice] = useState(0)

  // Total price calculation
  const totalPriceCalcul = (data)=>{
    let temp = 0
    // if data needed for an optimal update
    if (data !== undefined){
      Object.keys(data).map((key, index) => {
        let food = data.[key]
        temp += parseInt(food.count)*food.price
      })
    }
    // if data not needed
    else{
      Object.keys(cart).map((key, index) =>{
        let food = cart.[key]
        temp += parseInt(food.count)*food.price
      })
    }

    setTotalPrice(temp)
    return totalPrice
  }

  
  // Update quantity in cart
  const updateCart = (key, quantity)=>{
    let temp = {...cart}
    temp.[key].count = parseInt(quantity)
    setCart(temp)
    if (temp.[key].count === 0) {
      delete temp.[key]
    }
    totalPriceCalcul()
  }

  // Add to cart
  const addCart = (data, quantity, isModalButton)=> {
    let temp = {...cart}
    let cartLenght = Object.keys(cart).length


    // check if it's modal button or has choice
    if (isModalButton === false && data.choice !== "") {
      setShowModal(true)
      setDataModal(data)
    }

    // check if it's not modal button or has no choice
    else if (isModalButton === true || data.choice === "") {
      // console.log('it is modal button');
    
      // Fix impossible quantity
      if (quantity === 0) {
        quantity = 1
      }

      // Local function to add in the cart object 
      const putInCart = (data)=>{
        temp.[cartLenght] = {
          id: data._id,
          name: data.name,
          choice: data.choice,
          price: data.price,
          count: parseInt(quantity)
        }
      }


      

      // if no item in cart
      if (cartLenght === 0) {
        putInCart(data)
      }

      // check if item exist
      else{
        let alreadyExist = false
        let sameChoice = false

        if (data.choice !== "") {
          // Check if the choice is the same
          Object.keys(cart).map((value, index) => {
            let tempSameChoice = []

            if (cart[index].choice.length === data.choice.length) {
              Object.keys(cart[index].choice).map((key, index2)=>{
                if (cart[index].choice.[key].option[0] === data.choice.[key].option[0]) {
                  tempSameChoice.push(true)
                }
              })
            }

            if (tempSameChoice.length === data.choice.length) {
              sameChoice = true
            }
          })
        }
        else{
          sameChoice = true
        }


        Object.keys(cart).map((value, index) => {

          // If already exist or less than quantityMax
          if (Object.values(cart[value]).indexOf(data._id) > -1
              && temp[value].count < quantityMax
              && sameChoice){
            alreadyExist = true
            


            // Check choice
            let validation = false
            if (data.choice !== "") {
              let tempValidation = []
              Object.keys(temp[value].choice).map((key, index2)=>{
                if (temp[value].choice.[key].option[0] === data.choice.[key].option[0]) {
                  tempValidation.push(true)
                }
                if (tempValidation.length === 2) {
                  validation = true
                }
              })
            }
            else{
              validation = true
            }
          



            // Validate count
            if (validation) {
              // If count + quantity <= quantityMax, then do the calculation and change the count value
              if (temp[value].count + parseInt(quantity) <= quantityMax) {
                temp[value].count += parseInt(quantity)
                console.log(quantity);
                alreadyExist = true
              }
              // If count + quantity > quantityMax, then change count value to quantityMax
              else{
                temp[value].count = quantityMax
              }
            }
          }
        })
        if (alreadyExist === false) {
          putInCart(data)
        }
      }
      setCart(temp)
      totalPriceCalcul(temp)
    }
  }



  const allVar = {
    dataModal,
    setDataModal,
    showModal,
    setShowModal,
    cartName: cart,
    setCart,
    updateCart,
    addCart,
    quantityMax,
    totalPrice,
  }

  return (
    <AppContext.Provider value={allVar}>
      <React.Fragment>
        <ScrollToTop />
        <div className="container">
        { showModal ? <ChoiceModal /> : null }
        <Navbar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route component={Error} />
        </Switch>
        </div>
        <Footer />
      </React.Fragment>
    </AppContext.Provider>
  );
}

export default App;
