import React, { useContext } from "react";
import AppContext from "./AppContext";

const MenuCard = (props) => {
  const myContext = useContext(AppContext);
  const {data} = props

  return (
  <div className="menu-card">
    <div className="menu-card-head">
      <div className="menu-card-triangle">
        <img className="unselectable" src={data.triangle} alt=""/>
      </div>
      <div className="menu-card-food">
        <img className="unselectable" src={data.food} alt=""/>
      </div>
      <span className="menu-card-title">Menu</span>
      <span className="menu-card-subtitle">{data.title}</span>
      <span className="menu-card-price">{data.price}€</span>
    </div>
    <div className="menu-card-body">
      <div className="menu-card-category-container">
        <h5 className="menu-card-category">{data.categoryTitle01}</h5>
        <ul className="menu-card-list">
          {data.categoryItems01.map((value, index) => {
              return <li key={index}>{value}</li>
          })}
        </ul>
      </div>

      <div className="menu-card-category-container">
      <h5 className="menu-card-category">{data.categoryTitle02}</h5>
      <ul className="menu-card-list">
        {data.categoryItems02.map((value, index) => {
            return <li key={index}>{value}</li>
        })}
      </ul>
    </div>
    </div>
    <div onClick={()=>{myContext.addCart(data, 1, false)}} className="button-plus-primary">
      <div className="button-plus">
        <div className="button-plus-add-vertical"></div>
        <div className="button-plus-add-horizontal"></div>
      </div>
    </div>
  </div>
  )
}

export default MenuCard