import React from 'react'

const Footer = () => {
  return (
    <footer>
      <div className="copyright">
        2022 © - Réalisé avec ❤️ par <a href="https://lynch-cai.com/" target="_blank">Lynch Cai</a>
      </div>
    </footer>
  )
}

export default Footer
