import React, { useContext } from 'react';
import AppContext from "./AppContext";


const CartCard = (props) => {
  const myContext = useContext(AppContext);
  
  // Declare quantities
  const quantities = []
  for (let i = 0; i < parseInt(myContext.quantityMax)+1; i++) {
    quantities.push({value: i})
  }

  // Update count
  const updateCount = (event, key) =>{
    myContext.updateCart(key, event.target.value)
  }

  const updatePhoneText = ()=>{
    document.querySelector('#cart-card-button-phone').textContent = "Nous sommes fermés définitivement"
  }

  return (
    <div className="cart-card">
      <h5 className="cart-card-header">PANIER</h5>
      <div className="cart-card-items-container">
        {Object.keys(myContext.cartName).map((key, index) =>{
          let food = myContext.cartName.[key]
          return (
            <div key={key}>
              <div className="cart-card-body">
                <div className="cart-card-item-header">
                  <div className="cart-card-item-header-left">
                    <select id={food.id} value={food.count} onChange={(event) => {updateCount(event, key)}} className="cart-card-item-quantity select number">
                      {quantities.map((quantity, index) =>(
                        <option value={quantity.value} key={index}>{quantity.value}</option>
                      ))}
                    </select>
                      <div className="cart-card-item-name">{food.name}</div>
                  </div>
                  <div className="cart-card-item-header-right">
                    <div className="cart-card-item-price">{food.price*food.count}€</div>
                  </div>
                </div>
                <div className="cart-card-item-footer">
                  <ul className="cart-card-item-choice">
                    {Object.values(food.choice).map((key, index) => {
                      return (
                        <li key={index}>{food.choice.[index].option.[0]}</li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div className="cart-card-footer">
        <div className="cart-card-total">
          <div>Total</div>
          <div>
            {myContext.totalPrice}€
          </div>
        </div>
        <div className="cart-card-button">
          <div className="button-primary disabled">COMMANDER EN LIGNE (indisponible)</div>
          <div className="cart-card-footer-spacing"></div>
          <a href="tel:0000000000" className="button-primary" id="cart-card-button-phone" onClick={()=>{updatePhoneText()}}>COMMANDER PAR TÉLÉPHONE</a>
        </div>
      </div>
    </div>
  )
}

export default CartCard