// Important import
import React from 'react';
import { Element } from 'react-scroll'


// Components import
import MenuCard from '../components/MenuCard'
import FoodCard from '../components/FoodCard'
import CartCard from '../components/CartCard'


// Data import
import data from '../data.js'


// Resize
let sizes = {
  width: window.innerWidth,
  height: window.innerHeight
}
window.addEventListener(
  'resize',
  ()=>{
      sizes.width = window.innerWidth
      sizes.height = window.innerHeight
  }
)


// Scroll
document.addEventListener('scroll', event => {
  // Fixed menu & cart when scroll
  const posTrigger = document.querySelector('.navbar-logo').offsetHeight
  const navbar = document.querySelector('.navbar')
  const navbarSpaceFiller = document.querySelector('.navbar-space-filler')
  navbarSpaceFiller.style.height = `${navbar.offsetHeight}px`

  const cart = document.querySelector('.cart-card')

  if (posTrigger < window.scrollY){
    navbar.classList.add('scroll')
    navbarSpaceFiller.classList.add('active')

    if (sizes.width > 1180 && ((posTrigger + document.querySelector('.home-information-container').offsetHeight)) < window.scrollY) {
      cart.classList.add('scroll')
      cart.style.top = `${navbar.offsetHeight}px`
    }
    else{
      cart.classList.remove('scroll')
    }
  }
  else{
    navbar.classList.remove('scroll')
    navbarSpaceFiller.classList.remove('active')

    cart.classList.remove('scroll')
  }
})




const Home = ()=> {

  const showCart = ()=>{
    document.querySelector('.home-cart').classList.toggle('active')
    document.querySelector('.home-body').classList.toggle('blur')
    if (document.querySelector('.home-cart').classList.contains('active')) {
      document.querySelector('.home-cart-button').textContent = "Fermer votre panier"
    }
    else{
      document.querySelector('.home-cart-button').textContent = "Votre panier"
    }
  }



  return (
    <div className="home-container">
      <div className="home-information-container">
        <Element className="home-section-title">Information</Element>
        <p className="home-information-text">
        Le restaurant Impérial est définitivement fermé.
        <br/>Au cours de ces dix-sept merveilleuses années d'existence à Coulommiers,
        <br/>nous avons pu faire de très belles rencontres. Merci à chacun d’entre vous !
        </p>
        {/* <div className="home-information-title">Sur place:</div>
        <p className="home-information-text">12h00-14h30 et de 19h00-22h30, 7/7J
        <br/>Le Buffet à Volonté sur place le midi du Lundi au Vendredi est de 12€90.
        <br/>Le Buffet à Volonté sur place le soir, en weekend et pendant les jours fériés est de 16€90.
        </p>
        <div className="home-information-title">Emporter:</div>
        <p className="home-information-text">12h00-14h30 et de 19h00-22h30, 7/7J</p>
        <div className="home-information-title">Livraison:</div>
        <p className="home-information-text">À partir de 25€ (+3.5€ de frais de livraison)
        <br/>Villes concernées: Coulommiers, Mouroux, Boissy-Le-Châtel, Aulnoy, Chailly-En-Brie, Mauperthuis.
        <br/>Pour plus d'informations ou pour prendre commande, appelez nous au <a href="tel:0164030094">01 64 03 00 94</a>
        </p>
        <div className="home-information-title">Paiement:</div>
        <p className="home-information-text">Nous acceptons l'espèce, la carte bancaire, les tickets restaurant, les chèques et les Chèques-vacances</p> */}
      </div>

      <div className="home-body-container">
        <div className="home-body">
          {/* Menu */}
          <Element name="menus" className="home-section-title" >Menus</Element>
          {data.menu.map((dataMenu, index) => {
              return <MenuCard key={index} data={dataMenu}/>
          })}

          {/* Entrées */}
          <Element name="entrees" className="home-section-title" >Entrées</Element>
          <div className="home-food-card-container">
            {data.entrees.map((dataEntrees, index) => {
                return <FoodCard key={index} data={dataEntrees}/>
            })}
          </div>

          {/* Plats */}
          <Element name="plats" className="home-section-title" >Plats</Element>
          <div className="home-food-card-container">
            {data.plats.map((dataPlat, index) => {
                return <FoodCard key={index} data={dataPlat}/>
            })}
          </div>

          {/* Accompagnements */}
          <Element name="accompagnements" className="home-section-title" >Accompagnements</Element>
          <div className="home-food-card-container">
            {data.accompagnements.map((dataAccompagnement, index) => {
                return <FoodCard key={index} data={dataAccompagnement}/>
            })}
          </div>
          
          {/* Brochettes */}
          <Element name="brochettes" className="home-section-title" >Brochettes</Element>
          <div className="home-food-card-container">
            {data.brochettes.map((dataBrochette, index) => {
                return <FoodCard key={index} data={dataBrochette}/>
            })}
          </div>

          {/* Japonais */}
          <Element name="japonais" className="home-section-title" >Japonais</Element>
          <div className="home-food-card-container">
            {data.japonais.map((dataJaponais, index) => {
                return <FoodCard key={index} data={dataJaponais}/>
            })}
          </div>

          {/* Desserts */}
          <Element name="desserts" className="home-section-title" >Desserts</Element>
          <div className="home-food-card-container">
            {data.desserts.map((dataDessert, index) => {
                return <FoodCard key={index} data={dataDessert}/>
            })}
          </div>

          {/* Boissons */}
          <Element name="boissons" className="home-section-title" >Boissons</Element>
          <div className="home-food-card-container">
            {data.boissons.map((dataBoisson, index) => {
                return <FoodCard key={index} data={dataBoisson}/>
            })}
          </div>

          {/* Autres */}
          <Element name="autres" className="home-section-title" >Autres</Element>
          <div className="home-food-card-container">
            {data.autres.map((dataAutre, index) => {
                return <FoodCard key={index} data={dataAutre}/>
            })}
          </div>

        </div>
        <div className="home-cart">
          <CartCard />
        </div>
        <div onClick={()=>{showCart()}} className="home-cart-button button-primary">
            Votre panier 
        </div>
      </div>
    </div>
  )
}

export default Home
