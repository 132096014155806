import React, { useContext, useState } from "react";
import AppContext from "./AppContext";


const FoodCard = (props) => {
  const myContext = useContext(AppContext);
  const {data} = props
  const [count, setCount] = useState(0);

  // Declare quantities
  const quantities = []
  for (let i = 1; i < parseInt(myContext.quantityMax)+1; i++) {
    quantities.push({value: i})
  }

  const updateCount = (event) =>{
    setCount(event.target.value)
  }


  return (
  <div className="food-card">
    <div className="food-card-img">
      <img src={data.img} alt={data.name}/>
    </div>
    <div className="food-card-body">
      <div className="food-card-header">
        <div>{data.name}</div>
      </div>
      <div className="food-card-footer">
        <div className="food-card-price">{data.price}€</div>
        <div className="food-card-add-to-cart-container">
          <select value={count} onChange={(event)=>{updateCount(event)}} className="food-card-quantity select number" name="food-card-quantity">
            {quantities.map((quantity, index) =>(
              <option value={quantity.value} key={index}>{quantity.value}</option>
            ))}
          </select>
          <div onClick={()=>{myContext.addCart(data, count, false)}} className="button-primary small">Ajouter</div>
        </div> 
      </div>
    </div>
  </div>
  )
}

export default FoodCard