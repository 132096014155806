const data = {
  "menu":[
    {
      "_id": "menu01",
      "name": "Menu Chinois A",
      "title": "Chinois A",
      "triangle": "/svg/fade-triangle-red.svg",
      "food": "/svg/menu-food-red.svg",
      "categoryTitle01": "PLAT",
      "categoryTitle02": "ACCOMPAGNEMENT",
      "categoryItems01": [
        "Porc caramélisé",
        "Boulettes de poulet caramélisées",
        "Poulet caramélisé",
        "Poulet du chef",
        "Poulet au curry",
        "Poulet à l'ananas",
        "Boeuf aux oignons",
        "Boeuf pimenté",
        "Filet de cabillaud aigre douce",
        "Filet de cabillaud pimenté",
        "Légumes sautés"
      ],
      "categoryItems02": [
        "Riz nature",
        "Riz cantonnais",
        "Riz au curry",
        "Nouilles sautées",
        "Pommes de terre sautées"
      ],
      "choice": [
        {
          "_id": "01",
          "title": "Plats",
          "option": [
            "Porc caramélisé",
            "Boulettes de poulet caramélisées",
            "Poulet caramélisé",
            "Poulet du chef",
            "Poulet au curry",
            "Poulet à l'ananas",
            "Boeuf aux oignons",
            "Boeuf pimenté",
            "Filet de cabillaud aigre douce",
            "Filet de cabillaud pimenté",
            "Légumes sautés"
          ]
        },
        {
          "_id": "02",
          "title": "Plats",
          "option": [
            "Riz nature",
            "Riz cantonnais",
            "Riz au curry",
            "Nouilles sautées",
            "Pommes de terre sautées"
          ]
        }
      ],
      "price": "8"
    },
    {
      "_id": "menu02",
      "name": "Menu Chinois B",
      "title": "Chinois B",
      "triangle": "/svg/fade-triangle-orange.svg",
      "food": "/svg/menu-food-orange.svg",
      "categoryTitle01": "PLAT",
      "categoryTitle02": "ACCOMPAGNEMENT",
      "categoryItems01": [
        "Crevettes piquantes",
        "Crevettes sel et poivre",
        "Canard laqué",
      ],
      "categoryItems02": [
        "Riz nature",
        "Riz cantonnais",
        "Riz au curry",
        "Nouilles sautées",
        "Pommes de terre sautées"
      ],
      "choice": [
        {
          "_id": "01",
          "title": "Plats",
          "option": [
            "Crevettes piquantes",
            "Crevettes sel et poivre",
            "Canard laqué",
            "Boeuf pimenté"
          ]
        },
        {
          "_id": "02",
          "title": "Plats",
          "option": [
            "Riz nature",
            "Riz cantonnais",
            "Riz au curry",
            "Nouilles sautées",
            "Pommes de terre sautées"
          ]
        }
      ],
      "price": "10"
    },
    {
      "_id": "menu03",
      "name": "Menu Japonais A",
      "title": "Japonais A",
      "triangle": "/svg/fade-triangle-pink.svg",
      "food": "/svg/menu-food-pink.svg",
      "categoryTitle01": "15 PIÈCES",
      "categoryTitle02": "",
      "categoryItems01": [
        "5 Sushi saumon",
        "10 California (au choix)"
      ],
      "categoryItems02": [
        ""
      ],
      "choice": [
        {
          "_id": "01",
          "title": "Lot de california n°1",
          "option": [
            "Saumon et avocat",
            "Saumon et concombre",
            "Saumon et cheese",
            "Saumon et surimi"
          ]
        },
        {
          "_id": "02",
          "title": "Lot de california n°2",
          "option": [
            "Saumon et avocat",
            "Saumon et concombre",
            "Saumon et cheese",
            "Saumon et surimi"
          ]
        },
      ],
      "price": "10"
    },
    {
      "_id": "menu04",
      "name": "Menu Japonais B",
      "title": "Japonais B",
      "triangle": "/svg/fade-triangle-purple.svg",
      "food": "/svg/menu-food-purple.svg",
      "categoryTitle01": "21 PIÈCES",
      "categoryTitle02": "",
      "categoryItems01": [
        "5 Sushi saumon",
        "6 California (au choix)",
        "10 Maki (au choix)"
      ],
      "categoryItems02": [
        ""
      ],
      "choice": [
        {
          "_id": "01",
          "title": "Lot de maki n°1",
          "option": [
            "Saumon",
            "Avocat",
            "Concombre",
            "Cheese",
            "Surimi"
          ]
        },
        {
          "_id": "02",
          "title": "Lot de maki n°2",
          "option": [
            "Saumon",
            "Avocat",
            "Concombre",
            "Cheese",
            "Surimi"
          ]
        },
        {
          "_id": "03",
          "title": "Lot de california",
          "option": [
            "Saumon et avocat",
            "Saumon et concombre",
            "Saumon et cheese",
            "Saumon et surimi"
          ]
        },
      ],
      "price": "13"
    }
  ],
  "japonais":[
    {
      "_id": "japonais01",
      "name": "Sushi saumon (x4)",
      "choice": "",
      "img": "/imgs/foods/japonais/sushi_saumon.png",
      "price": "5"
    },
    {
      "_id": "japonais02",
      "name": "Sashimi saumon (x6)",
      "choice": "",
      "img": "/imgs/foods/japonais/sashimi_saumon.png",
      "price": "4.5"
    },
    {
      "_id": "japonais03",
      "name": "Maki (au choix) (x6)",
      "choice": [
        {
          "_id": "01",
          "title": "Ingrédient",
          "option": [
            "Saumon",
            "Avocat",
            "Concombre",
            "Cheese",
            "Surimi"
          ]
        }
      ],
      "img": "/imgs/foods/japonais/maki.png",
      "price": "4"
    },
    {
      "_id": "japonais04",
      "name": "California (au choix) (x6)",
      "choice": [
        {
          "_id": "01",
          "title": "Ingrédient",
          "option": [
            "Saumon et avocat",
            "Saumon et concombre",
            "Saumon et cheese",
            "Saumon et surimi"
          ]
        }
      ],
      "img": "/imgs/foods/japonais/california.png",
      "price": "4.5"
    },
    {
      "_id": "japonais05",
      "name": "California roll tempura (au choix) (x6)",
      "choice": [
        {
          "_id": "01",
          "title": "Ingrédient",
          "option": [
            "Crevette et avocat",
            "Crevette et concombre",
          ]
        }
      ],
      "img": "/imgs/foods/japonais/california_tempura.png",
      "price": "6.5"
    },
    {
      "_id": "japonais06",
      "name": "Las Vegas (au choix) (x6)",
      "choice": [
        {
          "_id": "01",
          "title": "Ingrédient",
          "option": [
            "Saumon et avocat",
            "Saumon et concombre",
            "Saumon et cheese",
            "Saumon et surimi"
          ]
        }
      ],
      "img": "/imgs/foods/japonais/lasvegas.png",
      "price": "6.5"
    },
    {
      "_id": "japonais07",
      "name": "Neige roll (au choix) (x6)",
      "choice": [
        {
          "_id": "01",
          "title": "Ingrédient",
          "option": [
            "Saumon et avocat",
            "Saumon et concombre",
            "Saumon et cheese",
            "Saumon et surimi"
          ]
        }
      ],
      "img": "/imgs/foods/japonais/neige_roll.png",
      "price": "4.5"
    },
  ],
  "entrees":[
    {
      "_id": "entree01",
      "choice": "",
      "name": "Nem au poulet (x4)",
      "img": "/imgs/foods/entrees/nem_poulet.png",
      "price": "3"
    },
    {
      "_id": "entree02",
      "choice": "",
      "name": "Nem aux crevettes (x4)",
      "img": "/imgs/foods/entrees/nem_crevette.png",
      "price": "3"
    },
    {
      "_id": "entree03",
      "choice": "",
      "name": "Samoussa au boeuf (x4)",
      "img": "/imgs/foods/entrees/samoussa.png",
      "price": "3"
    },
    {
      "_id": "entree04",
      "choice": "",
      "name": "Tempura aux crevettes (x4)",
      "img": "/imgs/foods/entrees/tempura.png",
      "price": "5"
    },
    {
      "_id": "entree05",
      "choice": "",
      "name": "Beignet de calamar (x6)",
      "img": "/svg/noImg.svg",
      "price": "3"
    },
    {
      "_id": "entree06",
      "choice": "",
      "name": "Beignet parfumé St-Jacque (x6)",
      "img": "/svg/noImg.svg",
      "price": "3"
    },
    {
      "_id": "entree07",
      "choice": "",
      "name": "Pince de crabe (x4)",
      "img": "/svg/noImg.svg",
      "price": "3"
    },
    {
      "_id": "entree08",
      "choice": "",
      "name": "Frites",
      "img": "/imgs/foods/entrees/frite.png",
      "price": "2.5"
    },
    {
      "_id": "entree09",
      "choice": "",
      "name": "Gyoza au poulet (x6)",
      "img": "/imgs/foods/entrees/gyoza.png",
      "price": "5"
    },
    {
      "_id": "entree10",
      "choice": "",
      "name": "Ravioli aux crevettes (x4)",
      "img": "/imgs/foods/entrees/ravioli.png",
      "price": "3"
    },
    {
      "_id": "entree11",
      "choice": "",
      "name": "Bouché aux crevettes (x4)",
      "img": "/imgs/foods/entrees/ravioli.png",
      "price": "3"
    },
    {
      "_id": "entree12",
      "choice": "",
      "name": "Rouleau de printemps (x2)",
      "img": "/imgs/foods/entrees/rouleau_printemps.png",
      "price": "3.5"
    },
    {
      "_id": "entree13",
      "choice": "",
      "name": "Salade soja crevette ou poulet",
      "img": "/imgs/foods/entrees/salade_soja.png",
      "price": "3"
    },
    {
      "_id": "entree14",
      "choice": "",
      "name": "Salade japonaise",
      "img": "/imgs/foods/entrees/salade_japonaise.png",
      "price": "2.5"
    }
  ],
  "accompagnements":[
    {
      "_id": "accompagnement01",
      "choice": "",
      "name": "Riz cantonais",
      "img": "/imgs/foods/accompagnements/riz_cantonnais.png",
      "price": "3.5"
    },
    {
      "_id": "accompagnement02",
      "choice": "",
      "name": "Riz au curry",
      "img": "/imgs/foods/accompagnements/riz_curry.png",
      "price": "3.5"
    },
    {
      "_id": "accompagnement03",
      "choice": "",
      "name": "Riz nature",
      "img": "/svg/noImg.svg",
      "price": "3"
    },
    {
      "_id": "accompagnement04",
      "choice": "",
      "name": "Nouille sautées",
      "img": "/imgs/foods/accompagnements/nouille.png",
      "price": "3.5"
    },
    {
      "_id": "accompagnement05",
      "choice": "",
      "name": "Pommes de terre sautées",
      "img": "/svg/noImg.svg",
      "price": "3.5"
    },
  ],
  "plats":[
    {
      "_id": "plat01",
      "choice": "",
      "name": "Porc caramélisé",
      "img": "/imgs/foods/plats/porc_caramel.png",
      "price": "5.5"
    },
    {
      "_id": "plat02",
      "choice": "",
      "name": "Boulette de poulet caramélisé",
      "img": "/svg/noImg.svg",
      "price": "5.5"
    },
    {
      "_id": "plat03",
      "choice": "",
      "name": "Poulet caramélisé",
      "img": "/svg/noImg.svg",
      "price": "5.5"
    },
    {
      "_id": "plat04",
      "choice": "",
      "name": "Poulet du chef",
      "img": "/imgs/foods/plats/poulet_chef.png",
      "price": "5.5"
    },
    {
      "_id": "plat05",
      "choice": "",
      "name": "Poulet au curry",
      "img": "/imgs/foods/plats/poulet_curry.png",
      "price": "5.5"
    },
    {
      "_id": "plat06",
      "choice": "",
      "name": "Poulet à l'ananas",
      "img": "/imgs/foods/plats/poulet_ananas.png",
      "price": "5.5"
    },
    {
      "_id": "plat07",
      "choice": "",
      "name": "Boeuf aux oignons",
      "img": "/imgs/foods/plats/boeuf_oignon.png",
      "price": "6"
    },
    {
      "_id": "plat08",
      "choice": "",
      "name": "Boeuf pimenté",
      "img": "/imgs/foods/plats/boeuf_oignon.png",
      "price": "6"
    },
    {
      "_id": "plat09",
      "choice": "",
      "name": "Canard laqué",
      "img": "/imgs/foods/plats/canard_laque.png",
      "price": "7.5"
    },
    {
      "_id": "plat10",
      "choice": "",
      "name": "Crevettes piquantes",
      "img": "/imgs/foods/plats/crevette_piquante.png",
      "price": "8.5"
    },
    {
      "_id": "plat11",
      "choice": "",
      "name": "Crevettes sel et poivre",
      "img": "/imgs/foods/plats/crevette_sel_poivre.png",
      "price": "7.5"
    },
    {
      "_id": "plat12",
      "choice": "",
      "name": "Filet de cabillaud pimenté",
      "img": "/imgs/foods/plats/filet_cabillaud.png",
      "price": "5.5"
    },
    {
      "_id": "plat13",
      "choice": "",
      "name": "Filet de cabillaud aigre-douce",
      "img": "/imgs/foods/plats/filet_cabillaud.png",
      "price": "5.5"
    },
    {
      "_id": "plat14",
      "choice": "",
      "name": "Légumes sautés",
      "img": "/imgs/foods/plats/legume_saute.png",
      "price": "4.5"
    },
  ],
  "brochettes":[
    {
      "_id": "brochette01",
      "choice": "",
      "name": "Brochette poulet sucrée (x5)",
      "img": "/imgs/foods/brochettes/brochette_poulet_sucre.png",
      "price": "5"
    },
    {
      "_id": "brochette02",
      "choice": "",
      "name": "Brochette poulet saté (x2)",
      "img": "/imgs/foods/brochettes/brochette_poulet_sate.png",
      "price": "3"
    },
    {
      "_id": "brochette03",
      "choice": "",
      "name": "Brochette boulette poulet (x3)",
      "img": "/imgs/foods/brochettes/brochette_boulette_poulet.png",
      "price": "4"
    },
    {
      "_id": "brochette04",
      "choice": "",
      "name": "Brochette boeuf-fromage (x5)",
      "img": "/imgs/foods/brochettes/brochette_boeuf_fromage.png",
      "price": "5"
    },
    {
      "_id": "brochette05",
      "choice": "",
      "name": "Brochette crevette grillée (x5)",
      "img": "/imgs/foods/brochettes/brochette_crevette.png",
      "price": "5"
    },
  ],
  "boissons":[
    {
      "_id": "boisson01",
      "choice": "",
      "name": "Coca cola (33cl)",
      "img": "/imgs/foods/boissons/cocacola.png",
      "price": "2"
    },
    {
      "_id": "boisson02",
      "choice": "",
      "name": "Coca cola sans sucre (33cl)",
      "img": "/svg/noImg.svg",
      "price": "2"
    },
    {
      "_id": "boisson03",
      "choice": "",
      "name": "Fanta (33cl)",
      "img": "/imgs/foods/boissons/fanta.png",
      "price": "2"
    },
    {
      "_id": "boisson04",
      "choice": "",
      "name": "Jus de litchi (33cl)",
      "img": "/imgs/foods/boissons/jus_litchi.png",
      "price": "2.5"
    },
    {
      "_id": "boisson05",
      "choice": "",
      "name": "Jus de coco (33cl)",
      "img": "/imgs/foods/boissons/jus_coco.png",
      "price": "2.5"
    },
    {
      "_id": "boisson06",
      "choice": "",
      "name": "Eau minérale (50cl)",
      "img": "/svg/noImg.svg",
      "price": "1.5"
    },
    {
      "_id": "boisson07",
      "choice": "",
      "name": "Eau minérale (1 litre)",
      "img": "/svg/noImg.svg",
      "price": "2.5"
    },
    {
      "_id": "boisson08",
      "choice": "",
      "name": "Badoit (50cl)",
      "img": "/imgs/foods/boissons/badoit_50cl.png",
      "price": "2.5"
    },
    {
      "_id": "boisson09",
      "choice": "",
      "name": "Badoit (1 litre)",
      "img": "/imgs/foods/boissons/badoit_1l.png",
      "price": "3.2"
    },
    {
      "_id": "boisson10",
      "choice": "",
      "name": "Bière chinoise Tsingtao (33cl)",
      "img": "/imgs/foods/boissons/tsingtao.png",
      "price": "3.3"
    },
    {
      "_id": "boisson11",
      "choice": "",
      "name": "Bière japonaise Asahi (33cl)",
      "img": "/imgs/foods/boissons/asahi.png",
      "price": "3.3"
    },
    {
      "_id": "boisson12",
      "choice": "",
      "name": "Bière Heineken (33cl)",
      "img": "/imgs/foods/boissons/heineken.png",
      "price": "3.3"
    },
  ],
  "desserts":[
    {
      "_id": "dessert01",
      "choice": [
        {
          "_id": "01",
          "title": "Perle de coco",
          "option": [
            "Nature",
            "Chocolat",
          ]
        }
      ],
      "name": "Perle de coco (x2)",
      "img": "/imgs/foods/desserts/perle_coco.png",
      "price": "2"
    },
    {
      "_id": "dessert02",
      "choice": "",
      "name": "Litchi",
      "img": "/imgs/foods/desserts/litchi.png",
      "price": "2"
    },
    {
      "_id": "dessert03",
      "choice": "",
      "name": "Arbouse",
      "img": "/svg/noImg.svg",
      "price": "2"
    },
    {
      "_id": "dessert04",
      "choice": "",
      "name": "Nougat chinois",
      "img": "/imgs/foods/desserts/nougat.png",
      "price": "2"
    },
    {
      "_id": "dessert05",
      "choice": "",
      "name": "Gingembre confit",
      "img": "/imgs/foods/desserts/gingembre.png",
      "price": "2"
    },
  ],
  "autres":[
    {
      "_id": "autre01",
      "choice": "",
      "name": "Bo bun",
      "img": "/svg/noImg.svg",
      "price": "6.5"
    },
  ],
}

export default data;