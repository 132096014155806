import React, { Component } from 'react'
import { Link } from 'react-scroll'
import ScrollMenu from 'react-horizontal-scrolling-menu';









// list of items
const list = [
  { name: 'MENUS', to: 'menus'},
  { name: 'ENTRÉES', to: 'entrees'},
  { name: 'PLATS', to: 'plats'},
  { name: 'ACCOMPAGNEMENTS', to: 'accompagnements' },
  { name: 'BROCHETTES', to: 'brochettes' },
  { name: 'JAPONAIS', to: 'japonais' },
  { name: 'DESSERTS', to: 'desserts' },
  { name: 'BOISSONS', to: 'boissons' },
  { name: 'AUTRES', to: 'autres' },
];


// One item component
// selected prop will be passed
const MenuItem = ({text, selected, to}) => {
  return <li><Link
    className={`navbar-element ${selected ? 'active' : ''}`}
    activeClass="active"
    spy={true}
    smooth={true}
    duration={500}
    to={to}
    >{text}</Link></li>;
};

// All items component
// Important! add unique key
export const Menu = (list, selected, to) =>
  list.map(el => {
    const name = el.name;
    const url = el.to
    return <MenuItem text={name} key={name} selected={selected} to={url} />;
  });


const Arrow = ({ text, className }) => {
  return (
    <div
      className={className}
    >{text}</div>
  );
};


const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });

const selected = 'item1';


class Navbar extends Component {
  constructor(props) {
    super(props);
    // call it again if items count changes
    this.menuItems = Menu(list, selected);
  }

  state = {
  };

  onSelect = key => {
    this.setState({ selected: key });
  }

  render() {
    const { selected } = this.state;
    // Create menu from items
    const menu = this.menuItems;

    return (
      <nav className="navbar-container">
        <div className="navbar-logo">
          <a href="/">
            <img src='/imgs/logo-blanc.png' alt="logo"/>
          </a>
        </div>
        <div className="navbar-space-filler"></div>
        <ul className="navbar">
          <ScrollMenu
            data={menu}
            arrowLeft={ArrowLeft}
            arrowRight={ArrowRight}
            selected={selected}
            onSelect={this.onSelect}
            inertiaScrolling="true"
            inertiaScrollingSlowdown="0.25"
          />
        </ul>
      </nav>
    );
  }
}
  
export default Navbar;







